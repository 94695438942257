<template>
  <div class="text-right">
    <v-dialog v-model="dialog" max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          <v-icon left>mdi-plus</v-icon>
          <span>Добавить</span>
        </v-btn>
      </template>
      <v-card :loading="loading" outlined>
        <v-card-title>Новая категория</v-card-title>
        <v-card-text>
          <v-text-field label="Название" v-model="category.title"></v-text-field>
          <v-textarea label="Описание" v-model="category.description"></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="createCategory">Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      category: this.createFreshCategoryObject()
    }
  },
  methods: {
    createCategory() {
      this.loading = true
      this.$store
        .dispatch('hardwareCategory/createCategory', this.category)
        .then(() => {
          this.loading = false
          this.dialog = false
          this.category = this.createFreshCategoryObject()
        })
        .catch(() => {
          this.loading = false
        })
    },
    createFreshCategoryObject() {
      return {
        title: '',
        description: ''
      }
    }
  }
}
</script>
