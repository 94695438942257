<template>
  <div>
    <h1 class="subtitle-1 grey--text">Категории устройств</h1>
    <HardwareCategoryDialogCreate />

    <v-container class="mb-5">
      <HardwareCategoryCard v-for="category in hardwareCategory.categories" :key="category.id" :category="category" />
    </v-container>

    <template v-if="page != 1">
      <router-link :to="{ name: 'hardware-category-list', query: { page: page - 1 } }" rel="prev">Prev Page</router-link>
      <template v-if="hasNextPage"> | </template>
    </template>
    <router-link v-if="hasNextPage" :to="{ name: 'hardware-category-list', query: { page: page + 1 } }" rel="next"> Next Page</router-link>
  </div>
</template>

<script>
import HardwareCategoryCard from '@/components/HardwareCategoryCard'
import HardwareCategoryDialogCreate from '@/components/HardwareCategoryDialogCreate'
import { mapState } from 'vuex'
import store from '@/store/index'

function getPageCategories(routeTo, next) {
  const currentPage = parseInt(routeTo.query.page || 1)
  store
    .dispatch('hardwareCategory/fetchPageCategories', {
      page: currentPage
    })
    .then(() => {
      routeTo.params.page = currentPage
      next()
    })
}

export default {
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  components: {
    HardwareCategoryCard,
    HardwareCategoryDialogCreate
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getPageCategories(routeTo, next)
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getPageCategories(routeTo, next)
  },
  computed: {
    hasNextPage() {
      return this.hardwareCategory.categoriesTotal > this.page * this.perPage
    },
    ...mapState(['hardwareCategory', 'user'])
  }
}
</script>
